<template>
  <b-overlay>
    <app-collapse accordion>
      <app-collapse-item
        v-for="(item, index) in data"
        :key="index"
        title=""
        class="my-2"
        variant="warning"
      >
        <template #header>
          <div class="w-100" v-on:click="isClick(item.id)">
            <b-badge variant="primary" class="badge-padding">
              {{ item.area_name }}
            </b-badge>
          </div>
        </template>

        <b-row>
          <b-button
            variant="primary"
            class="d-flex align-items-center mx-1"
            @click="handleModal('add-price', item)"
          >
            <FeatherIcon icon="PlusCircleIcon" />
            <span class="mx-1">Add New Price</span>
          </b-button>
          <b-button
            variant="primary"
            class="d-flex align-items-center"
            @click="extractPostcode()"
          >
            <FeatherIcon icon="ApertureIcon" />
            <span class="mx-1">Re-fetch table data</span>
          </b-button>
        </b-row>

        <div class="d-flex my-2 align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <b-form-input v-model="postcodeFrom" placeholder="Postcode From" />
            <h5 class="mx-1 d-flex align-items-center">to</h5>
            <b-form-input v-model="postcodeTo" placeholder="Postcode To" />
            <b-input-group-append @click="extractPostcode">
              <b-input-group-text class="bg-primary text-white ml-1">
                Extract Postcode
              </b-input-group-text>
            </b-input-group-append>
          </div>
          <b-input-group class="w-25">
            <b-form-input
              v-model="serverParams.searchTerm"
              placeholder="Search"
              @input="loadItems()"
            />
            <b-input-group-append>
              <b-input-group-text class="bg-primary text-white">
                Search
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </div>

        <vue-good-table
          :key="restart"
          style-class="vgt-table striped"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
          }"
          :rows="rows"
          :columns="columns"
        >
          <div slot="emptystate" class="text-center">
            <span v-if="!isLoading">No Data Found</span>
            <b-spinner v-else variant="primary" />
          </div>
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'unit_price'">
              {{ $helpers.formatTotal(props.row.unit_price) }}
            </div>

            <div v-else-if="props.column.field === 'double_charge'">
              {{ $helpers.formatTotal(props.row.double_charge) }}
            </div>

            <div v-else-if="props.column.field === 'actions'">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="handleModal('edit-price', props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="handleDelete(props.row)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div v-else>
              {{ props.formattedRow[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
        <EditTaxCharge :data="item" />
      </app-collapse-item>
    </app-collapse>

    <b-modal ref="modal" :title="modal.title" hide-footer no-close-on-backdrop>
      <AddPrice
        v-if="modal.type === 'add-price'"
        :data="modal.data"
        @added="handleUpdate"
      />
      <EditPrice
        v-else-if="modal.type === 'edit-price'"
        :data="modal.data"
        @updated="handleUpdate"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AddPrice from "@/views/pages/inside/PriceChecker/AddPrice.vue";
import EditPrice from "@/views/pages/inside/PriceChecker/EditPrice.vue";
import EditTaxCharge from "@/views/pages/inside/PriceChecker/EditTaxCharge.vue";
import MyTable from "@/views/components/MyTable.vue";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    MyTable,
    AddPrice,
    EditPrice,
    EditTaxCharge,
    VueGoodTable,
  },

  data() {
    return {
      restart: 0,
      postcodeFrom: "",
      postcodeTo: "",
      isLoading: false,
      data: [],
      modal: {
        title: "",
        type: "",
        data: [],
      },
      columns: [
        {
          label: "Start Postcode",
          field: "postcode_range_from",
          tdClass: "text-center",
        },
        {
          label: "End Postcode",
          field: "postcode_range_to",
          tdClass: "text-center",
        },
        {
          label: "Unit Price",
          field: "unit_price",
          tdClass: "text-center",
        },
        {
          label: "Double Charge",
          field: "double_charge",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          field: "actions",
          tdClass: "text-center",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchTerm: "",
        column: [
          { label: "id", field: "id", sortable: false },
          {
            label: "price_checker_id",
            field: "price_checker_id",
            sortable: false,
          },
          {
            label: "postcode_range_from",
            field: "postcode_range_from",
            sortable: false,
            filterOptions: {
              enabled: true,
              filterValue: "01000", // initial populated value for this filter
              trigger: "enter",
            },
          },
          {
            label: "postcode_range_to",
            field: "postcode_range_to",
            sortable: false,
            filterOptions: {
              enabled: true,
              filterValue: "10000", // initial populated value for this filter
              trigger: "enter",
            },
          },
          { label: "unit_price", field: "unit_price", sortable: false },
          { label: "double_charge", field: "double_charge", sortable: false },
        ],
        sort: [
          {
            field: "id",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
        customParameter: [{ field: "price_checker_id", value: "" }],
      },
    };
  },

  created() {
    this.getPriceChecker();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    isClick(id) {
      this.isLoading = true;
      this.serverParams.customParameter[0].value = id;
      this.loadItems();
    },
    async getPriceChecker() {
      this.isLoading = true;
      const response = await this.$http.get("pricechecker");

      this.data = response.data.data;
      this.isLoading = false;
    },

    extractPostcode() {
      if (this.postcodeFrom && this.postcodeTo) {
        this.serverParams.customParameter.splice(1, 2);
        this.serverParams.customParameter.push({
          field: "postcode_range_from",
          value: this.postcodeFrom,
        });
        this.serverParams.customParameter.push({
          field: "postcode_range_to",
          value: this.postcodeTo,
        });
      } else {
        this.serverParams.customParameter.splice(1, 2);
      }
      this.serverParams.page = 1;
      this.restart += 1;
      this.isLoading = true;
      this.loadItems();
    },

    loadItems() {
      this.$http.post(`get_prices_data`, this.serverParams).then((response) => {
        this.totalRecords = response.data.totalRecords;
        this.rows = response.data.rows;
      });
      this.isLoading = false;
    },

    handleModal(type, data = []) {
      const title = type.replace("-", " ").toLocaleUpperCase();
      this.modal = {
        title,
        type,
        data,
      };
      this.$refs.modal.show();
    },

    handleUpdate() {
      this.getPriceChecker();
      this.loadItems();
      this.$refs.modal.hide();
    },

    async handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to delete ${row.state} with postcode range from ${row.postcode_range_from} to ${row.postcode_range_to}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-2",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.$http.delete(`pricechecker/${row.id}`);
          if (response.data.status === true) {
            this.getPriceChecker();
            this.loadItems();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.dropdown-container {
  width: 500px;
}
</style>
